import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";

export default function Template({
	data, // this prop will be injected by the GraphQL query below.
}) {
	const { markdownRemark } = data; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;

	return (<Layout>
		<SEO title={frontmatter.title} />
		<article>
			<header>
				<h1>{frontmatter.title}</h1>
				<p>{frontmatter.date}</p>
			</header>
		
			<div
				className="markdown-content"
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</article>
	</Layout>
	);

}

export const pageQuery = graphql`
  	query($url: String!) {
    	markdownRemark(frontmatter: { url: { eq: $url } }) {
			html
			frontmatter {
				slug
				title
				url
				date(formatString:"DD MMMM YYYY")
			}
    	}
  	}
`;
